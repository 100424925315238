<template>
  <div class="card card-custom">
    <div class="card-body">
      <b-table
        ref="dublettenCleaningTable"
        responsive
        id="dublettenCleaningTable"
        primary-key="id"
        @sort-changed="onSortChange"
        :sort-by="orderBy[0].split(' ')[0]"
        :sort-direction="orderBy[0].split(' ')[1]"
        :empty-filtered-text="$t('DUBLETTEN.NODUBLETTENFOUND')"
        :empty-text="$t('DUBLETTEN.EMPTYTEXT')"
        show-empty
        no-local-sorting
        no-sort-reset
        :items="kunden"
        :busy.sync="isBusy"
        :sticky-header="tableHeight + 'px'"
        :fields="fields"
        :tbody-tr-class="rowClass"
      >
        <template #cell(id)="row">
          {{ row.value }}
          <b-icon-calendar-event
            variant="primary"
            v-if="row.item.vorgaenge.length > 0"
          ></b-icon-calendar-event>
          <b-button
            v-if="hasKundeValidationState(row.item, 'ApprovedAuto')"
            @click="onRetryClick(row.item.id)"
            class="p-1"
            variant="outline-primary"
            size="sm"
          >
            <b-icon icon="arrow-repeat"></b-icon>
            {{ $t('DUBLETTEN.ERNEUTBESTAETIGEN') }}
          </b-button>
        </template>

        <template #cell(updatedDateUtc)="row"> {{ row.value | dateTime }}</template>

        <template #cell(name)="row">
          {{ parseName(row.item.name) }}
        </template>

        <template #cell(email)="row">
          {{ row.item.email }}
        </template>

        <template #cell(kommentar)="row">
          <TableCommentCell
            :comment="row.item.kommentar"
            :id="row.item.id"
            :saveCommentFunction="saveCommentFunction"
          ></TableCommentCell>
        </template>

        <template #cell(actions)="row">
          <ActionButton
            :title="$t('DUBLETTEN.NEUANSTOSSEN')"
            iconClass="b-icon-arrow-counterclockwise"
            @click="onClickRefreshCleaning(row.item.id)"
          />
        </template>

        <template #cell()="row">
          <KundeDublettenCleaningCellDisplay
            v-if="row.value"
            :fieldKey="row.field.key"
            :validationState="row.value.validationState"
            :resultCodes="row.value.resultCodes"
            :errorCodes="row.value.errorCodes"
            :validationString="row.value.validation"
            :validationObject="row.value.validationObject"
            :valueString="row.value.value"
            :valueObject="row.value.valueObject"
            @approveColumn="onButtonClickColumn(row.item.id, row.field.approveTrigger, row.field.key)"
            @discardColumn="onButtonClickColumn(row.item.id, row.field.discardTrigger, row.field.key)"
            @editColumn="onEditVorschlagButtonClick(row.item)"
          ></KundeDublettenCleaningCellDisplay>
          <div v-else>-</div>
        </template>
      </b-table>
      <SKRPagination
        v-model="currentPage"
        :isLoading="isBusy"
        :totalRows="count"
        :currentRows="kunden.length || 0"
        ariaControls="dublettenCleaningTable"
        @pageChange="onPageChange"
      ></SKRPagination>
    </div>
    <CleaningOverwriteModal
      :kunde="modalKunde"
      @onModalSubmit="onModalSubmit"
      @onModalHide="modalKunde = null"
    ></CleaningOverwriteModal>
  </div>
</template>

<style lang="scss" scoped>
:deep(.b-table-sticky-header > .table.b-table > thead > tr > th) {
  position: sticky !important;
}
// Needs fixed height to make children relative height possible
:deep(.fixed-height-td) {
  height: 1px;
}
</style>

<script>
import { mapState } from 'vuex';

import {
  KUNDEN_CLEANING_SORT_CHANGED,
  KUNDEN_CLEANING_PAGE_CHANGED,
  KUNDEN_CLEANING_TRIGGER_CLEANING_BY_ID,
  KUNDEN_CLEANING_TRIGGER_VALIDATION_RETRY_BY_ID,
  KUNDEN_CLEANING_SEND_TRIGGER_BY_ID,
  KUNDEN_CLEANING_PATCH_VALIDATION_DATA,
  MARK_CLEANING_KUNDE_AS_CHANGED,
  SAVE_KUNDE_CLEANING_COMMENT,
} from '@/core/kunden/stores/kunden-dubletten-cleaning-filter.module';
import KundeDublettenCleaningTableToolbarBase from '@/components/kunden/dubletten-cleaning-table-toolbar-base.vue';
import KundeDublettenCleaningCellDisplay from '@/components/kunden/dubletten-cleaning-table-cell-display.vue';
import SKRPagination from '@/components/common/skr-pagination.vue';
import ActionButton from '@/components/common/action-button.vue';

import CleaningOverwriteModal from '@/components/kunden/cleaning-overwrite-modal.vue';

import TableCommentCell from '@/components/kunden/table-comment-cell.vue';

export default {
  components: {
    SKRPagination,
    KundeDublettenCleaningCellDisplay,
    KundeDublettenCleaningTableToolbarBase,
    ActionButton,
    TableCommentCell,
    CleaningOverwriteModal,
  },
  props: {
    tableHeight: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      options: [
        { text: 'Approved', value: 'Approved' },
        { text: 'New', value: 'New' },
      ],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'updatedDateUtc',
          label: this.$t('COMMON.GEANDERT'),
          sortable: true,
        },
        {
          key: 'name',
          label: this.$t('REISEN.COMMON.NAME'),
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'adresse',
          label: this.$t('COMMON.ADRESSE'),
          approveTrigger: 2,
          discardTrigger: 8,
        },
        {
          key: 'telefonMobil',
          label: this.$t('COMMON.TELMOBILSHORT'),
          approveTrigger: 5,
          discardTrigger: 11,
        },
        {
          key: 'telefonPrivat',
          label: this.$t('COMMON.TELEFONPRIVAT'),
          approveTrigger: 3,
          discardTrigger: 9,
        },
        {
          key: 'telefonGeschaeftlich',
          label: this.$t('COMMON.TELGESCHAEFTLICHSHORT'),
          approveTrigger: 4,
          discardTrigger: 10,
        },
        {
          key: 'kommentar',
          label: this.$t('REISEN.COMMON.COMMENT'),
          tdClass: 'fixed-height-td',
        },
        {
          key: 'actions',
          label: this.$t('MENU.ACTIONS'),
        },
      ],
      allFields: ['adresse', 'telefonMobil', 'telefonPrivat', 'telefonGeschaeftlich'],
      modalKunde: null,
    };
  },
  computed: {
    ...mapState({
      kunden: state => state.kundeDublettenCleaningFilter.kunden,
      isBusy: state => state.kundeDublettenCleaningFilter.isBusy,
      count: state => state.kundeDublettenCleaningFilter.count,
      skip: state => state.kundeDublettenCleaningFilter.skip,
      top: state => state.kundeDublettenCleaningFilter.top,
      orderBy: state => state.kundeDublettenCleaningFilter.orderBy,
    }),
  },
  methods: {
    parseName(name) {
      return `${name.anrede ? name.anrede + ' ' : ''}${name?.titel ? name.titel + ' ' : ''}${
        name?.vorname ? name.vorname + ' ' : ''
      }${name?.nachname || ''}`;
    },
    hasKundeValidationState(kunde, validationState) {
      // Define fields to check validation state against
      const fieldsToCheck = ['adresse', 'telefonGeschaeftlich', 'telefonMobil', 'telefonPrivat'];
      // Return true if any field has the specified validationState, false otherwise
      return fieldsToCheck.some(field => kunde?.[field]?.validationState === validationState);
    },
    saveCommentFunction(id, comment) {
      return this.$store.dispatch(SAVE_KUNDE_CLEANING_COMMENT, { id: id, comment: comment });
    },
    onEditVorschlagButtonClick(kunde) {
      this.modalKunde = { ...kunde };
      this.$bvModal.show('modal-kunden-cleaning-overwrite');
    },
    rowClass(item, type) {
      return item && type === 'row' && item.changed ? 'bg-warning-o-50' : '';
    },
    onRetryClick(id) {
      this.sendRetryForKunde(id);
    },
    onButtonClickColumn(id, trigger, key) {
      this.sendTriggerForColumn(id, trigger, key);
    },
    onClickRefreshCleaning(id) {
      this.$store.dispatch(KUNDEN_CLEANING_TRIGGER_CLEANING_BY_ID, { id: id }).then(() => {
        this.$bvToast.toast(this.$t('KUNDEN.RETRY_MESSAGE'), {
          autoHideDelay: 5000,
          variant: 'info',
          noCloseButton: false,
        });
      });
    },
    onSortChange(ctx) {
      this.$store.dispatch(KUNDEN_CLEANING_SORT_CHANGED, { sortContext: ctx });
      this.currentPage = 1;
    },
    onPageChange(ctx) {
      this.$store.dispatch(KUNDEN_CLEANING_PAGE_CHANGED, { pageContext: ctx });
      this.$refs.dublettenCleaningTable.$el.scrollTop = 0;
      this.currentPage = 1;
    },
    async sendRetryForKunde(id) {
      this.$store.dispatch(KUNDEN_CLEANING_TRIGGER_VALIDATION_RETRY_BY_ID, { id: id }).then(() => {
        this.$bvToast.toast(this.$t('KUNDEN.RETRY_MESSAGE'), {
          autoHideDelay: 5000,
          variant: 'info',
          noCloseButton: false,
        });
      });
    },
    async sendTriggerForColumn(id, trigger) {
      this.$store.dispatch(KUNDEN_CLEANING_SEND_TRIGGER_BY_ID, { id: id, trigger: trigger }).then(() => {
        this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
      });
    },
    onModalSubmit(changedKunde) {
      if (changedKunde) {
        this.$store
          .dispatch(KUNDEN_CLEANING_PATCH_VALIDATION_DATA, { kunde: changedKunde })
          .then(response => {
            this.updateKundeOrSendTrigger(response, changedKunde);
          });
      } else {
        this.updateKundeOrSendTrigger(null, this.modalKunde);
      }
    },
    async updateKundeOrSendTrigger(response, kunde) {
      // Helper function to check if there's no validation for a specific field in adresse
      const noValidation = field => !response?.data?.result?.value?.adresse?.[field]?.validation;

      // Check if kunde has a valid result and none of the address fields has validation
      const shouldUpdateKunde =
        response?.data?.result?.value && ['strasse', 'postleitzahl', 'ort', 'land'].every(noValidation);

      if (shouldUpdateKunde) {
        this.$store.dispatch(MARK_CLEANING_KUNDE_AS_CHANGED, { kunde: kunde });
        this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
      } else {
        this.sendTriggerForColumn(kunde.id, 2, 'adresse');
      }

      // Close the modal and reset modalKunde
      this.$bvModal.hide('modal-kunden-cleaning-overwrite');
      this.modalKunde = null;
    },
  },
};
</script>
