<template>
  <div>
    <span
      v-for="(diff, index) in stringDifferences"
      :key="'diffparts-' + index"
      :class="diff[0] === -1 ? 'bg-danger-o-50' : diff[0] === 1 ? 'bg-success-o-50' : ''"
      >{{ diff[0] === -1 ? diff[1].replaceAll(' ', '&nbsp;') : diff[1] }}</span
    >
  </div>
</template>

<script>
const diff = require('fast-diff');

export default {
  props: {
    firstString: { type: String, required: true },
    secondString: { type: String, required: true },
  },
  computed: {
    stringDifferences() {
      return diff(this.firstString, this.secondString);
    },
  },
};
</script>
