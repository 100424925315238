<template>
  <div class="d-flex flex-column flex-grow-1">
    <FilterId
      class="mt-4"
      @input="setKundenIdFilter"
      :optionsArray="kundenIdFilterOptions"
      :value="kundenIdFilter"
      :storeFunction="getKundenIdFilterFunction"
      :placeholder="$t('DUBLETTEN.FILTER.KUNDENNUMMER')"
      objectName="Kunde"
    ></FilterId>
    <KundeDublettenCleaningFilterValidationState
      class="mt-4"
      :value="validationStateFilter"
      @input="setValidationStateFilter"
    ></KundeDublettenCleaningFilterValidationState>
    <div class="w-100 mt-4 flex-grow-1 flex-column">
      <b-button
        :disabled="areAppliedKundeDublettenCleaningFiltersEqualsSetFilters"
        lg
        block
        @click="onClickSaveFilter($event)"
        variant="primary"
        >Filter anwenden</b-button
      >
      <b-button
        block
        variant="danger"
        @click="onClickResetFilter($event)"
        v-show="isKundeDublettenCleaningFilterResettable"
        v-t="'REISEN.COMMON.RESET'"
      >
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import {
  SET_ALL_FILTER_TO_INTIAL,
  SET_KUNDEN_ID_FILTER,
  GET_KUNDEN_ID_FILTER_OPTIONS,
  SET_VALIDATION_STATE_FILTER,
} from '@/core/kunden/stores/kunden-dubletten-cleaning-filter.module';
import FilterId from '@/components/common/filter-id.vue';
import KundeDublettenCleaningFilterValidationState from '@/components/kunden/dubletten-cleaning-filter-validation-state.vue';

export default {
  components: {
    FilterId,
    KundeDublettenCleaningFilterValidationState,
  },
  props: {
    defaultBearbeiter: { type: Array },
    selectedBearbeiter: { type: Array },
  },
  computed: {
    getKundenIdFilterAction() {
      return GET_KUNDEN_ID_FILTER_OPTIONS;
    },
    ...mapState({
      kundenIdFilterOptions: state => state.kundeDublettenCleaningFilter.kundenIdFilterOptions,
      kundenIdFilter: state => state.kundeDublettenCleaningFilter.filter.kundenIdFilter,
      validationStateFilter: state => state.kundeDublettenCleaningFilter.filter.validationStateFilter,
    }),
    ...mapGetters([
      'areAppliedKundeDublettenCleaningFiltersEqualsSetFilters',
      'isKundeDublettenCleaningFilterResettable',
    ]),
  },
  mounted() {},
  methods: {
    getKundenIdFilterFunction(id) {
      return this.$store.dispatch(GET_KUNDEN_ID_FILTER_OPTIONS, { idFilter: id });
    },
    onClickSaveFilter($event) {
      this.$emit('onClickSaveFilter', $event);
    },
    onClickResetFilter($event) {
      this.$store.commit(SET_ALL_FILTER_TO_INTIAL);
      this.$emit('onClickResetFilter', $event);
    },
    setKundenIdFilter(kunden) {
      this.$store.commit(SET_KUNDEN_ID_FILTER, kunden);
    },
    setValidationStateFilter(validationStateFilterKey) {
      this.$store.commit(SET_VALIDATION_STATE_FILTER, validationStateFilterKey);
    },
  },
};
</script>
