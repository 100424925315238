<template>
  <div class="d-flex flex-column">
    <div ref="diff-display-div">
      <div v-if="difference && validationState === 'New'" class="m-1">
        <DifferenceHighlight :firstString="valueString" :secondString="validationString" />
      </div>
      <div v-else class="text-muted">
        {{ validationString || valueString }}
      </div>
      <div v-if="isError">
        <b-badge :variant="validationState === 'Error' ? 'danger' : 'primary'">{{ validationState }}</b-badge>
      </div>
    </div>
    <div v-if="validationString && validationState === 'New'" class="my-1 mr-1">
      <b-button @click="$emit('approveColumn')" class="p-1 mr-1" variant="outline-primary" size="sm">
        Übernehmen
      </b-button>
      <b-button
        v-if="fieldKey === 'adresse'"
        @click="$emit('editColumn')"
        class="p-1 mt-1"
        variant="outline-secondary"
        size="sm"
      >
        Vorschlag ändern
      </b-button>
    </div>
    <div v-if="validationState === 'Error'" class="my-1 mr-1">
      <b-button @click="$emit('discardColumn')" class="p-1" variant="outline-primary" size="sm">
        Ignorieren
      </b-button>
    </div>
    <b-popover
      :target="() => $refs['diff-display-div']"
      triggers="hover"
      placement="bottom"
      boundary="viewport"
    >
      <table class="table table-sm table-borderless">
        <thead>
          <tr>
            <th class="p-0"></th>
            <th class="p-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-muted">{{ $t('DUBLETTEN.STATE') }}:</td>
            <td>
              {{ validationState || '-' }}
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.AKTUELL') }}:</td>
            <td>
              {{ valueString || '-' }}
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.NEU') }}:</td>
            <td>
              {{ validationString || '-' }}
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('COMMON.UNTERSCHIED') }}:</td>
            <td v-if="difference">
              <DifferenceHighlight :firstString="valueString" :secondString="validationString" />
            </td>
            <td v-else>-</td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('KUNDEN.DUBLETTEN.CLEANING.RESULTCODES') }}:</td>
            <td>
              <MelissaCode
                v-for="(code, index) in resultCodes"
                :key="code"
                :code="code"
                :isSeperated="index !== resultCodes.length - 1"
              />
            </td>
          </tr>
          <tr>
            <td class="text-muted">{{ $t('KUNDEN.DUBLETTEN.CLEANING.ERRORCODES') }}:</td>
            <td>
              <MelissaCode
                v-for="(code, index) in errorCodes"
                :key="code"
                :code="code"
                :isSeperated="index !== errorCodes.length - 1"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </b-popover>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import MelissaCode from '@/components/common/melissa-code.vue';
import DifferenceHighlight from '@/components/common/difference-highlight.vue';
const diff = require('fast-diff');
export default {
  components: { MelissaCode, DifferenceHighlight },
  props: {
    fieldKey: { type: String },
    validationString: { type: String },
    valueString: { type: String },
    validationState: { type: String },
    validationObject: { type: Object },
    valueObject: { type: Object },
    resultCodes: { type: Array },
    errorCodes: { type: Array },
  },
  computed: {
    isError() {
      return this.validationState === 'Error';
    },
    difference() {
      return this.valueString && this.validationString ? diff(this.valueString, this.validationString) : null;
    },
  },
};
</script>
