<template>
  <div>
    <TableAggregationsToolbar
      v-if="!isInitalLoading && aggregations"
      :aggregations="aggregations"
    ></TableAggregationsToolbar>
    <b-skeleton v-if="isInitalLoading" width="300px" height="24px"></b-skeleton>
  </div>
</template>

<script>
import TableAggregationsToolbar from '@/components/common/table-aggregations-toolbar';
import apiService from '@/core/common/services/api.service';

export default {
  components: { TableAggregationsToolbar },
  props: {
    isParentLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: true,
      isInitalLoading: true,
      aggregations: null,
    };
  },
  watch: {
    isParentLoading: {
      immediate: true,
      handler: async function (isLoading) {
        if (isLoading) {
          await this.getAllAggretations();
        }
      },
    },
  },
  methods: {
    async getAllAggretations() {
      this.isLoading = true;
      let aggregationsData = await this.getAggregations();
      const errorWithBookingCount = await this.getErrorWithBookingAggregation();
      let transformedAggregate = this.transformAggregateDate(aggregationsData);
      transformedAggregate['isErrorWithBooking'] = errorWithBookingCount;
      this.isLoading = false;
      this.aggregations = [
        {
          data: transformedAggregate.isNew,
          title: this.$t('COMMON.NEU'),
          iconClass: 'fas fa-plus text-primary',
        },
        {
          data: transformedAggregate.isError,
          title: this.$t('DUBLETTEN.FILTER.ERROR'),
          iconClass: 'fas fa-ban text-danger',
        },
        {
          data: transformedAggregate.isErrorWithBooking,
          title: this.$t('DUBLETTEN.FILTER.ERRORWITHBOOKING'),
          iconClass: 'fas fa-calendar-times text-danger',
        },
        {
          data: transformedAggregate.isDiscardedError,
          title: this.$t('DUBLETTEN.FILTER.DISCARDEDERRORS'),
          iconClass: 'fas fa-backspace text-info',
        },
        {
          data: transformedAggregate.isApproved,
          title: this.$t('DUBLETTEN.FILTER.AENDERUNGBESTAETIGT'),
          iconClass: 'fas fa-bullseye text-success',
        },
        {
          data: transformedAggregate.isCompleted,
          title: this.$t('DUBLETTEN.FILTER.CORRECTIONDONE'),
          iconClass: 'fas fa-check text-success',
        },
      ];
      this.isInitalLoading = false;
    },
    async getAggregations() {
      return apiService
        .get(
          // eslint-disable-next-line max-len
          "Kunde?$apply=filter(isDeleted eq false)/compute((Adresse/validationState eq 'New' or TelefonPrivat/validationState eq 'New' or TelefonGeschaeftlich/validationState eq 'New' or TelefonMobil/validationState eq 'New') as isNew,(adresse/validationState eq 'Error' or TelefonPrivat/validationState eq 'Error' or TelefonGeschaeftlich/validationState eq 'Error' or TelefonMobil/validationState eq 'Error') as isError,( adresse/validationState eq 'Approved' or TelefonPrivat/validationState eq 'Approved' or TelefonGeschaeftlich/validationState eq 'Approved' or TelefonMobil/validationState eq 'Approved' or adresse/validationState eq 'ApprovedAuto' or TelefonPrivat/validationState eq 'ApprovedAuto' or TelefonGeschaeftlich/validationState eq 'ApprovedAuto' or TelefonMobil/validationState eq 'ApprovedAuto') as isApproved,(adresse/validationState eq 'DiscardedError' or TelefonPrivat/validationState eq 'DiscardedError' or TelefonGeschaeftlich/validationState eq 'DiscardedError' or TelefonMobil/validationState eq 'DiscardedError') as isDiscardedError,(ValidationStateKunde eq 'Validated') as isCompleted)/groupby((isNew,isError,isApproved,isDiscardedError,isCompleted),aggregate(id with countdistinct as count))"
        )
        .then(response => {
          return response.data.result.value;
        })
        .catch(error => {
          console.error(error);
        });
    },
    // Has to be split in two requests because of url length
    // Can not be aggregation because any is not supported with aggregate
    async getErrorWithBookingAggregation() {
      this.isLoading = true;
      return apiService
        .get(
          // eslint-disable-next-line max-len
          "Kunde?$filter=isDeleted eq false and ((((Adresse/ValidationState eq 'Error') or (TelefonPrivat/ValidationState eq 'Error') or (TelefonGeschaeftlich/ValidationState eq 'Error') or (TelefonMobil/ValidationState eq 'Error'))) and (vorgaenge/any(vorgaenge:(vorgaenge/reisedatum ge now()) and (vorgaenge/blankState eq 'Buchung'))))&$expand=Name,Adresse($expand=Strasse,Postleitzahl,Ort,Land),TelefonPrivat,TelefonGeschaeftlich,TelefonMobil,vorgaenge($filter=(reisedatum ge now()) and (blankState eq 'Buchung'))&$count=true&$top=0"
        )
        .then(response => {
          return response.data.result['@odata.count'] || 0;
        })
        .catch(error => {
          console.error(error);
        });
    },
    transformAggregateDate(data) {
      return data.reduce(
        (acc, cur) => {
          if (cur.isNew) {
            acc.isNew += cur.count;
          }
          if (cur.isError) {
            acc.isError += cur.count;
          }
          if (cur.isApproved) {
            acc.isApproved += cur.count;
          }
          if (cur.isDiscardedError) {
            acc.isDiscardedError += cur.count;
          }
          if (cur.isCompleted) {
            acc.isCompleted += cur.count;
          }
          if (!cur.isNew && !cur.isError && !cur.isApproved && !cur.isDiscardedError && !cur.isCompleted) {
            acc.isNone += cur.count;
          }
          acc.total += cur.count;
          return acc;
        },
        { isNew: 0, isError: 0, isApproved: 0, isDiscardedError: 0, isCompleted: 0, isNone: 0, total: 0 }
      );
    },
  },
};
</script>
