<template>
  <div>
    <FilterOverview title="Bereinigung" :isInitiallyLoading="isInitiallyLoading" @reload="onReload">
      <template #toolbar-right>
        <KundeDublettenCleaningTableToolbarBase
          :isParentLoading="isBusy"
        ></KundeDublettenCleaningTableToolbarBase
      ></template>
      <template #filter>
        <KundeDublettenCleaningFilter @onClickSaveFilter="onSaveFilter" @onClickResetFilter="onResetFilter"
      /></template>
      <template #table="{ tableHeight, heightComponentInlineStyle }">
        <KundeDublettenCleaningTable
          :tableHeight="tableHeight"
          :heightComponentInlineStyle="heightComponentInlineStyle"
          :isBusy="isBusy"
        />
      </template>
    </FilterOverview>
  </div>
</template>
<style lang="scss" scoped>
.card-filter-nav {
  overflow-y: auto;
}
</style>
<script>
import {
  SET_APPLIED_FILTER,
  GET_KUNDEN,
  SET_KUNDEN_CLEANING_SKIP,
} from '@/core/kunden/stores/kunden-dubletten-cleaning-filter.module';
import KundeDublettenCleaningTableToolbarBase from '@/components/kunden/dubletten-cleaning-table-toolbar-base.vue';

import KundeDublettenCleaningTable from '@/components/kunden/dubletten-cleaning-table.vue';
import KundeDublettenCleaningFilter from '@/components/kunden/dubletten-cleaning-filter.vue';
import FilterOverview from '@/components/common/filter-overview.vue';

import { mapState } from 'vuex';
import EventBus from '@/core/common/plugins/event-bus.js';

export default {
  components: {
    KundeDublettenCleaningTable,
    KundeDublettenCleaningFilter,
    KundeDublettenCleaningTableToolbarBase,
    FilterOverview,
  },
  props: {
    title: { type: String, default: 'Prozesse' },
  },
  computed: {
    ...mapState({
      isBusy: state => state.kundeDublettenCleaningFilter.isBusy,
      isInitiallyLoading: state => state.kundeDublettenCleaningFilter.isInitiallyLoading,
      kundeDublettenCleaningFilter: state => state.kundeDublettenCleaningFilter.filter,
    }),
  },
  mounted() {
    this.$store.dispatch(GET_KUNDEN);

    EventBus.$on('reload', () => {
      this.$store.dispatch(GET_KUNDEN);
    });
  },
  beforeDestroy() {
    EventBus.$off('reload');
  },
  methods: {
    onReload() {},
    onSaveFilter() {
      this.$store.commit(SET_APPLIED_FILTER, this.kundeDublettenCleaningFilter);
      this.$store.commit(SET_KUNDEN_CLEANING_SKIP, 0);
      this.$store.dispatch(GET_KUNDEN);
      this.filterHeight = 'auto';
    },
    onResetFilter() {
      this.$store.commit(SET_APPLIED_FILTER, this.kundeDublettenCleaningFilter);
      this.$store.commit(SET_KUNDEN_CLEANING_SKIP, 0);
      this.$store.dispatch(GET_KUNDEN);
      this.filterHeight = 'auto';
    },
  },
};
</script>
